import Cookies from 'js-cookie';

function setDarkMode(darkMode: boolean) {
    Cookies.set('color_scheme', darkMode ? 'dark' : 'light');
}

function onChangeColorScheme(darkMode: boolean) {
    if (Cookies.get('theme_sync_with_system') !== '1') {
        return;
    }

    let lightSidebar = Cookies.get('theme_light_sidebar');
    lightSidebar = lightSidebar ? lightSidebar.toString() : '';

    let darkSidebar = Cookies.get('theme_dark_sidebar');
    darkSidebar = darkSidebar? darkSidebar.toString() : '';

    const elements = document.getElementsByClassName('main-sidebar');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        element.classList.remove(darkMode ? lightSidebar : darkSidebar);
        element.classList.add(darkMode ? darkSidebar : lightSidebar);
    }

    let lightSkin = Cookies.get('theme_light_skin');
    lightSkin = lightSkin ? lightSkin.toString() : '';

    let darkSkin = Cookies.get('theme_dark_skin');
    darkSkin = darkSkin ? darkSkin.toString() : '';

    const body = document.getElementsByTagName('body')[0];
    if (body) {
        body.classList.remove(darkMode ? lightSkin : darkSkin);
        body.classList.add(darkMode ? darkSkin : lightSkin);
    }
}

setDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    const darkMode = event.matches;

    onChangeColorScheme(darkMode);
    setDarkMode(darkMode);
});
