















import { Component, Mixins, Prop } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { ValidationObserver } from "vee-validate";

@Component({
    components: {
        ValidationObserver
    }
})
export default class FForm extends Mixins(TranslateMixin) {
    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    @Prop({ type: [Object, String ], default: () => '' })
    rules!: string | Record<string, unknown>;

    async validate(): Promise<boolean> {
        return await this.$refs.observer.validate();
    }

    onSubmit() {
        this.$emit('submit');
    }
}
