var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("validation-provider", {
    attrs: {
      tag: "div",
      vid: _vm.vid,
      name: _vm.translate(_vm.$attrs.label),
      rules: _vm.rules
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(_ref) {
          var errors = _ref.errors
          return _c(
            "f-form-item",
            {
              attrs: {
                "error-messages": errors,
                label: _vm.$attrs.label,
                required: _vm.$attrs.required
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "input-group"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "input-group-prepend"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "input-group-text"
                        },
                        [_vm._v(_vm._s(_vm.symbol))]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.innerValue,
                        expression: "innerValue"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      disabled: _vm.$attrs.disabled
                    },
                    domProps: {
                      value: _vm.innerValue
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.innerValue = $event.target.value
                      }
                    }
                  })
                ]
              )
            ]
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }