var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("validation-provider", {
    attrs: {
      tag: "div",
      vid: _vm.vid,
      name: _vm.translate(_vm.$attrs.label),
      rules: _vm.rules
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(_ref) {
            var errors = _ref.errors
            return _c(
              "f-form-item",
              {
                attrs: {
                  "error-messages": errors,
                  label: _vm.$attrs.label,
                  required: _vm.$attrs.required
                }
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.innerValue,
                      expression: "innerValue"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: _vm.$attrs.name,
                    disabled: _vm.$attrs.disabled,
                    readonly: _vm.$attrs.readonly
                  },
                  domProps: {
                    value: _vm.innerValue
                  },
                  on: {
                    keyup: _vm.onkeyup,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.innerValue = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm._t("default")
              ],
              2
            )
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }