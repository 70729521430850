import notify, { NotificationType } from '@/util/notify';

$(function () {
    $('.noty-notification').each(function () {
        const text = $(this).html() as string;
        const type = $(this).data('type') as NotificationType;

        notify(type, text);
    });
});
