// Externe dependencies
import 'sweetalert';
import 'selectize';
import 'selectize/dist/js/standalone/selectize';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as toastr from 'toastr';
import axios from 'axios';
import './bootstrap';

import Router from './routing';

// Food
import './AgGrid/init';
import './util/alerts';
import './util/persistant-tabs';
import './util/CurrencyUtil';
import './util/collection';
import './components/form/form-components';

import Events from '@/util/events';
import translate from './util/translate';
import './util/global';
import './util/notifications';
import './util/skin';

// Sentry
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: 'https://b1f9b1d259fb49a7b89a3e48ab30d435@o529721.ingest.sentry.io/5675634',
        environment: 'prod',
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        // 1 op de 10 requests
        tracesSampleRate: 0.1,
    });
}

// Dependencies
global.$ = $;
global.jQuery = $;
global._ = _;
global.moment = moment;
global.Routing = Router;
global.Events = Events;
global.EventBus = new Vue();
global.toastr = toastr;
global.Sentry = Sentry;
global.isProduction = process.env.NODE_ENV === 'production';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
global.axios = axios;

// Utilities
global.popupWindow = (url, name, width, height) => {
    window.open(url, name, 'width=' + width + ',height=' + height);
};
global.translate = translate;
