class Events {
    static eventPrefix = 'food_events_';

    static emit(eventName: string, data?: any) {
        const event = new CustomEvent(Events.eventPrefix + eventName, {
            detail: data
        });

        window.dispatchEvent(event);
    }

    static on(eventName: string, handler: Function) {
        window.addEventListener(Events.eventPrefix + eventName, (event: CustomEvent) => {
            handler(event.detail);
        });
    }
}

export default Events;