import axios from 'axios';

class CurrencyUtil {
    private static initialized = false;
    private static currencies: Object = {};
    private static rates: Array<any>  = [];

    public static init() {
        // Get currencies
        axios.get('/currencies/rest/all').then(res => {
            CurrencyUtil.currencies = res.data;

            // Get currency rates
            axios.get('/api/v1/currency-rate').then(res => {
                CurrencyUtil.rates = res.data['data'];
                CurrencyUtil.initialized = true;
            });
        });
    }

    public static getSymbolById(id: string): string {
        if (typeof CurrencyUtil.currencies[id] === 'undefined') {
            return '◻';
        } else {
            return CurrencyUtil.currencies[id]['symbol'];
        }
    }

    public static convertCurrencyById(amount: number, fromId: string, toId: string) {
        for (const currencyRate of CurrencyUtil.rates) {
            if (currencyRate['fromCurrency']['id'] === fromId && currencyRate['toCurrency']['id'] === toId) {
                return amount * currencyRate['rate'];
            }
        }

        return null;
    }

    public static isInitialized(): boolean {
        return CurrencyUtil.initialized;
    }
}

export default CurrencyUtil;
