import $ from 'jquery';
import swal from 'sweetalert';

$(document).on('click', 'button[data-toggle="confirmation"]', function(e) {
    e.preventDefault();
    e.stopPropagation();

    const message = $(this).data('message');
    const form = $(this).closest('form');

    swal({
        title: message,
    }).then((result) => {
        if (result) {
            form.submit();
        }
    });
});

$(document).on('click', 'a[data-toggle="confirmation"]', function(e) {
    e.preventDefault();
    e.stopPropagation();

    const message = $(this).data('message');
    const target = $(this).attr('href');

    swal({
        title: message,
    }).then((result) => {
        if (result && target) {
            window.location.href = target;
        }
    });
});
