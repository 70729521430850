import Vue from 'vue';

Vue.component('FForm', () => import('./f-form.vue'));
Vue.component('FServerForm', () => import('./f-server-form.vue'));
Vue.component('FFormItem', () => import('./f-form-item.vue'));
Vue.component('FInput', () => import('./f-input.vue'));
Vue.component('FTextarea', () => import('./f-textarea.vue'));
Vue.component('FDatepicker', () => import('./f-datepicker.vue'));
Vue.component('FCheckbox', () => import('./f-checkbox.vue'));
Vue.component('FSelect', () => import('./f-select.vue'));
Vue.component('FMoneyInput', () => import('./f-money-input.vue'));
