




























import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { ValidationProvider } from 'vee-validate';
import FFormItem from '@/components/form/f-form-item.vue';

@Component({
    components: { ValidationProvider, FFormItem }
})
export default class FMoneyInput extends Mixins(TranslateMixin) {

    @Prop({ type: String })
    vid: string;

    @Prop({ type: [Object, String ] })
    rules: string | object;

    @Prop({ type: null })
    value: null;

    @Prop({ type: [String] })
    symbol: null;

    innerValue?: string = '';

    @Watch('innerValue')
    onInnerValueChanged(newVal) {
        this.$emit('input', newVal);
    }

    @Watch('value')
    onValueChanged(newVal) {
        this.innerValue = newVal;
    }
}
