var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("validation-observer", {
    ref: "observer",
    attrs: {
      tag: "div"
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(_ref) {
            var handleSubmit = _ref.handleSubmit
            return [
              _c(
                "form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return handleSubmit(_vm.onSubmit)
                    }
                  }
                },
                [_vm._t("default")],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }