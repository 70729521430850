
import { Component, Inject, Vue } from 'vue-property-decorator';
import { ModalContext } from '@/components/ui/button/modal';

@Component
export default class ModalAwareMixin extends Vue {
    @Inject({ default: null })
    modalContext!: ModalContext|null;

    isParentModalActive(): boolean {
        if (!this.modalContext) return false;

        return this.modalContext.visible;
    }

    hideParentModal(): void {
        if (!this.modalContext) return;

        return this.$hideModal(this.modalContext.modalId);
    }

    showParentModal(): void {
        if (!this.modalContext) return;

        return this.$showModal(this.modalContext.modalId);
    }
}
