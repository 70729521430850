
import { Component, Inject, Prop, Mixins } from 'vue-property-decorator';
import { TabItemContext } from '@/components/ui/tabs/tabContext';
import ModalAwareMixin from '@/components/ui/mixin/modal-aware-mixin.vue';
import { ModalContext } from '@/components/ui/button/modal';
import { AccordionItemContext } from '@/components/ui/accordion/accordionContext';

@Component
export default class VisibilityAwareMixin extends Mixins(ModalAwareMixin) {
    @Prop({ type: Boolean, default: true })
    active!: boolean;

    @Inject({ default: null })
    tabItemContext!: TabItemContext|null;

    @Inject({ default: null })
    modalContext!: ModalContext|null;

    @Inject({ default: null })
    accordionItemContext!: AccordionItemContext|null;

    get isActive(): boolean {
        if (!this.active) {
            return false;
        }

        if (this.modalContext) {
            return this.isParentModalActive();
        }

        if (this.tabItemContext) {
            return this.tabItemContext.active;
        }

        if (this.accordionItemContext) {
            return this.accordionItemContext.isActive();
        }

        return true;
    }
}
