const collectionIndices = {};
const indiceStart = 100; // Start at 100 to allow for prefilled rows.

export function incrementPrototypeIndex(indexName) {
    if (typeof collectionIndices[indexName] === 'undefined') {
        collectionIndices[indexName] = indiceStart;
    }
    collectionIndices[indexName]++;
}

export function getPrototypeIndex(indexName) {
    if (typeof collectionIndices[indexName] === 'undefined') {
        return indiceStart;
    }
    return collectionIndices[indexName];
}

$(document).on('click', '.sc-collection-add', function() {

    const prototypeTarget = $(this).data('sc-prototype-target');
    const collectionTarget = $(this).data('sc-collection-target');

    if (!prototypeTarget || !collectionTarget) {
        console.error('Missing data types, consult collection.ts for more information.');
    }

    const prototype = $(prototypeTarget).data('prototype');

    if (!prototype) {
        console.error('Missing prototype, consult collection.ts for more information.');
    }

    const newRow = prototype.replace(/__name__/g, getPrototypeIndex(prototypeTarget));
    incrementPrototypeIndex(prototypeTarget);

    $(collectionTarget).append(newRow);
});

$(document).on('click', '.sc-collection-delete', function() {

    const target = $(this).data('sc-target');

    if (!target) {
        console.error('Target for collection deletion could not be found, consult collection.ts for more information.');
    }

    const $target = $(this).closest(target);

    $target.remove();
});

