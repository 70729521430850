import { applyInputGroupDisables } from './global';

const hash = window.location.hash.includes('tab') ? window.location.hash : localStorage.getItem(window.location.pathname + '-activeTab');

const $tab = $(`.nav-tabs a[href="${hash}"]`);
if ($tab.length) {
    const nestedParent = $tab
        .closest('.nav.nav-tabs')
        .closest('.nav.nav-tabs');
    if (typeof nestedParent === 'undefined' || !nestedParent || nestedParent.length < 1) {
        $tab.tab('show');
    } else {
        const tab = '#' + $tab.closest('.tab-pane').attr('id');
        $(`.nav-tabs a[href="${tab}"]`).tab('show');
        $tab.tab('show');
    }
}

$(document).on('shown.bs.modal', function () {
    applyInputGroupDisables();
});

$(document).on('shown.bs.tab', '[data-toggle="tab"]', function (e) {
    const $target = $(e.target);
    const href = $target.attr('href');

    if (typeof href === 'string') {
        localStorage.setItem(window.location.pathname + '-activeTab', href);

        history.replaceState(null, '', href);
    }
});

// Deze code zorgt ervoor dat als je naar een andere tab kan switchen (bijv. via menu) op dezelfde pagina zonder tab link.
$(document).on('click', 'a', function(e) {
    const $target = $(e.target);
    const href = $target.attr('href');

    if (typeof href === 'undefined') {
        return;
    }

    const newLocation = '/' + href.substring(
        href.lastIndexOf('/') + 1,
        href.lastIndexOf('#')
    );

    const newHash = href.substring(href.lastIndexOf('#'));

    if (newLocation === location.pathname && newHash !== location.hash) {
        $(`.nav-tabs a[href="${newHash}"]`).tab('show');
    }
});
